/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/16 16:49
 * @description
 *   datafeed.ts of WeTrade
 */
import * as R from 'ramda'
import { BarTypes, readBars } from '@/modules/market/market.api'
import { Bar, SymbolDescriber, IDatafeed } from 'happychart'

const datafeed: IDatafeed = {
  resolveSymbol (symbol: string): Promise<SymbolDescriber> {
    return Promise.resolve({
      code: symbol,
      exchange: 'EX',
      priceScale: 0.01,
      minMove: 0.1,
      type: 'chart',
      /**
       * An array of resolutions which should be enabled in resolutions picker for this symbol.
       */
      resolutions: ['1S'],
    })
  },
  async read (symbol: SymbolDescriber): Promise<Bar[]> {
    const bars = await readBars({
      excode: 'EX',
      code: symbol.code,
      type: BarTypes.S1,
    })
    return R.map(bar => ({
      o: Number(bar.o ?? bar.c),
      h: Number(bar.h ?? bar.c),
      l: Number(bar.l ?? bar.c),
      c: Number(bar.c),
      t: bar.u * 1000,
    }), bars)
  },
  watch (): () => void {
    return () => {
      console.log('unwatch')
    }
  },
  unwatch () {
    return true
  },
}

export default datafeed
