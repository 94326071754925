
import Icon from '@/components/Icon.vue'
import { computed, defineComponent, ref } from 'vue'
import Matrix from 'common/Matrix.vue'
import ChoiceButton from '@/components/ChoiceButton.vue'

export default defineComponent({
  name: 'TheSelectorDialog',
  components: { Icon, Matrix, ChoiceButton },
  emits: ['close', 'change'],
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: Number,
  },
  setup (props, ctx) {
    const current = ref(props.selected || 0)

    const value = computed(() => props.options[current.value])

    const select = (index: number) => {
      current.value = index
      ctx.emit('change', index)
      ctx.emit('close')
    }

    return {
      value,
      select,
    }
  },
})
