
import Icon from '@/components/Icon.vue'
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { openDialog } from '@/components/popup/popup'
import TheSelectorDialog from '@/modules/fastTrade/components/TheSelectorDialog.vue'

export default defineComponent(
  {
    name: 'TheFiniteSelector',
    components: { Icon },
    emits: ['change'],
    props: {
      options: {
        type: Array,
        required: true,
      },
      createWithVoucher: Boolean,
    },
    setup (props, ctx) {
      const max = computed(() => props.options.length)
      const current = ref(0)
      const value = computed(() => props.options[current.value])

      const next = (dir: 1 | -1) => {
        const next = current.value + dir * 1
        const _max = max.value
        current.value = (next + _max) % _max
      }

      const openSelector = () => {
        openDialog(TheSelectorDialog, {
          options: props.options,
          selected: current.value,
          wrapperClass: 'center',
          onChange: (index: number) => {
            current.value = index
          },
        }, {
          class: 'dialog selector-dialog',
        })
      }

      watchEffect(() => {
        ctx.emit('change', value.value)
      })

      return {
        next,
        openSelector,
        value,
      }
    },

  },
)
