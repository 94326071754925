/**
* @author zjc[beica1@outook.com]
* @date 2021/8/18 17:34
* @description
*   ThePosition.vue of WeTrade
*/
<template>
  <div
    class="order"
    :style="{top: `${createY}px`, left: `${createX}px`, color: color, background: color}"
  >
    <div class="line ref" :style="`top: ${profitY}px`" data-text="TP"></div>
    <Icon :name="isBuy ? 'arr-up' : 'arr-down'" class="arrow ref" />
    <div class="line ref" :style="`top: ${lossY}px`" data-text="SL"></div>
  </div>
</template>

<script lang="ts">
import { randomInt } from '@/common/common'
import Icon from '@/components/Icon.vue'
import { readExistColor, storeOrderColor } from '@/modules/fastTrade/fastTrade'
import { FastOrderSchema } from '@/modules/fastTrade/history.api'
import { TradeDirection } from '@/types'
import { defineComponent, shallowRef, watch } from 'vue'

const colorPool = [
  '#4CD2FF', '#007CFF', '#A552F4', '#692AFF', '#FF00E5', '#FF7596', '#FFD400', '#FF5600',
]

export default defineComponent({
  name: 'ThePosition',
  components: { Icon },
  props: {
    order: {
      type: Object,
      required: true,
    },
    latest: Number,
    price: [String, Number],
    fx: {
      type: Function,
      required: true,
    },
    fy: {
      type: Function,
      required: true,
    },
  },
  setup (props) {
    const order = props.order as FastOrderSchema
    const existColor = readExistColor(order.orderId)
    const newColor = randomInt(0, colorPool.length - 1)
    const color = colorPool[existColor ?? newColor]
    const isBuy = order.buyType === TradeDirection.BUY
    const createY = shallowRef(props.fy(order.createPrice))
    const createX = shallowRef(props.fx(order.createTime))
    const profitY = shallowRef(props.fy(order.stopProfitPrice) - createY.value)
    const lossY = shallowRef(props.fy(order.stopLossPrice) - createY.value)

    if (!existColor) {
      storeOrderColor(order.orderId, newColor)
    }

    watch(() => [props.price, props.latest], () => {
      createY.value = props.fy(order.createPrice)
      createX.value = props.fx(order.createTime)
      profitY.value = props.fy(order.stopProfitPrice) - createY.value
      lossY.value = props.fy(order.stopLossPrice) - createY.value
    })

    return {
      color,
      isBuy,
      profitY,
      lossY,
      createX,
      createY,
    }
  },
})
</script>

<style scoped lang="scss">
.order {
  position: absolute;
  height: 0;
  right: 0;
}

.arrow {
  left: -7px;
  top: -7px;
  color: var(--color-white);
  border-radius: 50%;
  font-size: 11px;
  padding: 2px;
  background: inherit;
}

.ref {
  position: absolute;
}

.line {
  height: 1px;
  width: 100%;
  background: inherit;

  &::before {
    position: absolute;
    left: -7px;
    top: -7px;
    padding: 1px;
    border: 1px solid;
    border-color: inherit;
    border-radius: 1px;
    background: var(--color-white);
    font-size: 10px;
    content: attr(data-text);
  }
}
</style>
