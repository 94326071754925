/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/18 13:32
 * @description
 *   fastTrade.ts of WeTrade
 */
import { keymap } from '@/config'
import { localGet, localSet } from 'essential/store/localStore'
import { mul } from 'essential/tools/math'
import * as R from 'ramda'
import { ProductSchema } from '@/modules/fastTrade/fastTrade.api'
import { computed, shallowRef } from 'vue'

export interface Product {
  code: string;
  price: string;
  profit: number;
  fee: number;
  specification: string;
  stopProfitOffset: number;
  stopLossOffset: number;
}

export const calcStops = (schema: ProductSchema) => {
  return {
    profit: mul(schema.pointStep, schema.calculatePoint, schema.stopProfitPoint),
    loss: mul(schema.pointStep, schema.calculatePoint, schema.stopLossPoint),
  }
}

/**
 * @param schema
 */
export const generateProducts = (schema: ProductSchema): Product[] => {
  const stops = R.applySpec<Record<'stopProfitOffset' | 'stopLossOffset', number>>({
    stopProfitOffset: R.prop('profit'),
    stopLossOffset: R.prop('loss'),
  })(calcStops(schema))

  const prices = schema.prices.split(',')

  return R.map(price => ({
    code: schema.contract,
    price,
    profit: mul(price, schema.profitRate, 0.01),
    fee: mul(price, 100 - schema.profitRate, 0.01),
    specification: `${schema.contractVolume} ${schema.contract}`,
    ...stops,
  }), prices)
}

/**
 * stored = 'abc:2,ded:3,sfe:1'
 * @param id
 */
export const readExistColor = (id: number) => {
  const stored = localGet(keymap.label.color) ?? ''
  const matched = stored.match(new RegExp(`${id}:\\d,?`))
  if (matched) {
    return Number(matched[0].match(/:(\d+),?/)?.[1])
  }
}

export const storeOrderColor = (id: number, index: number) => {
  const stored = localGet(keymap.label.color) ?? ''
  const next = stored + `,${id}:${index}`
  localSet(keymap.label.color, next)
}

/**
 * 将字符串中不包含指定orderIds的子串移除
 * @param ids
 */
export const dropUnusedOrderColor = (ids: number[]) => {
  const colors = (localGet(keymap.label.color) ?? '').split(',')
  const nextColors = R.filter(info => {
    // 取字符串中的orderId
    const orderId = info.match(/\D?(\d+):/)?.[1]
    return R.contains(Number(orderId), ids)
  }, colors)
  localSet(keymap.label.color, nextColors.toString())
}

const _positionsCount = shallowRef(0)

export const positionsCount = computed(() => _positionsCount.value)

export const updatePositionsCount = (mix: number | ((count: number) => number)) => {
  if (typeof mix === 'number') {
    _positionsCount.value = mix
  } else if (typeof mix === 'function') {
    _positionsCount.value = mix(_positionsCount.value)
  }
}
