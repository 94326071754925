
import { marketFeed, QuoteMessage } from '@/common/datafeed'
import { events } from '@/config'
import datafeed from '@/modules/fastTrade/datafeed'
import ThePosition from '@/modules/fastTrade/drawings/ThePosition.vue'
import ThePriceLabel from '@/modules/fastTrade/drawings/ThePriceLabel.vue'
import TheStopLines from '@/modules/fastTrade/drawings/TheStopLines.vue'
import { TradeDirection } from '@/types'
import RatioFixedArea from 'common/RatioFixedArea.vue'
import { off, on } from 'essential/tools/event'
import { add } from 'essential/tools/math'
import HappyChart, { ChartType, TimeUnit } from 'happychart'
import { computed, defineComponent, onBeforeUnmount, onMounted, shallowRef, watch } from 'vue'
import Chart from 'happychart/chart/Chart'

export default defineComponent({
  name: 'TheChart',
  components: { ThePosition, TheStopLines, ThePriceLabel, RatioFixedArea },
  props: {
    code: {
      type: String,
      required: true,
    },
    positions: {
      type: Array,
      default: () => [],
    },
    stops: {
      type: Object,
      default: () => ({
        profit: 0,
        loss: 0,
      }),
    },
    type: Number,
  },
  setup (props) {
    const position = shallowRef({
      top: '0',
      left: '0',
    })
    const price = shallowRef('')
    const latest = shallowRef(Date.now())
    const chart = shallowRef()

    const topOffset = computed(() => {
      return props.type === TradeDirection.BUY ? props.stops.profit : props.stops.loss
    })
    const bottomOffset = computed(() => {
      return props.type === TradeDirection.BUY ? props.stops.loss : props.stops.profit
    })

    let happyChart: Chart | null = null
    let hc: HappyChart | null = null

    const fy = (price: number) => {
      return happyChart?.yAxis.fy(price) ?? 0
    }

    const fx = (time: number) => {
      return happyChart?.xAxis.fxRound(time) ?? 0
    }

    onMounted(() => {
      hc = new HappyChart({
        pan: false,
        container: chart.value,
        type: ChartType.AREA,
        periodicity: {
          timeUnit: TimeUnit.SECOND,
        },
        layout: {
          candleWidth: 2,
          whitespace: {
            top: 10,
            right: 80,
            bottom: 10,
          },
        },
        style: {
          lineWidth: 2,
          lineColor: '#326BFE',
        },
        xAxis: {
          borderColor: '#F4F6F8',
          lineColor: '#F4F6F8',
        },
        yAxis: {
          borderColor: '#F4F6F8',
          lineColor: '#F4F6F8',
          medianBy: 'custom',
          extent (data, low, high) {
            if (props.stops && data.length) {
              const latestPrice = data.slice(-1)[0].c
              const expectLow = add(latestPrice, -bottomOffset.value)
              const expectHigh = add(latestPrice, topOffset.value)
              return [Math.min(low, expectLow), Math.max(high, expectHigh)]
            }
            return [low, high]
          },
        },
      })

      hc.attachFeed(datafeed)

      let gradient = ''

      happyChart = hc.loadChart(props.code, {
        style: {
          fillColor: () => `url(#${gradient})`,
        },
      })

      gradient = happyChart.defines.add(
        {
          type: 'linearGradient',
          stops: [
            {
              color: '#326BFE',
              opacity: 0.2,
              offset: '0%',
            }, {
              color: '#fff',
              offset: '100%',
              opacity: 0.2,
            },
          ],
        },
      )

      const onTick = (quote?: QuoteMessage) => {
        if (quote) {
          hc?.streamData({
            last: Number(quote.sell),
          }, Number(quote.updatetime))
        }
      }

      let unwatch = marketFeed.watch(props.code, onTick)

      happyChart.onTick((bar, x, y) => {
        price.value = bar.c.toString()
        latest.value = bar.t
        position.value = {
          top: `${y - 7}px`,
          left: `${x}px`,
        }
      })

      watch(() => props.code, value => {
        if (value) {
          unwatch()
          hc?.changeSymbol(props.code)
          unwatch = marketFeed.watch(value, onTick)
        }
      })

      onBeforeUnmount(() => {
        unwatch()
        hc?.destroy()
      })
    })

    const refresh = () => {
      marketFeed.subscribe([props.code])
      hc?.loadChartData()
    }

    on(events.activated, refresh)

    onBeforeUnmount(() => {
      off(events.activated, refresh)
    })

    return {
      chart,
      position,
      latest,
      price,
      fx,
      fy,
    }
  },
})
