/**
 * @author zjc[beica1@outook.com]
 * @date 2021/8/18 10:43
 * @description
 *   fastTrade.api.ts of WeTrade
 */
import requestResult, { requestByAccountType } from '@/common/request/request'
import withAccountUpdate from '@/decorators/withAccountUpdate'
import withoutCryptos from '@/decorators/withoutCryptos'
import { FastOrderSchema } from '@/modules/fastTrade/history.api'

export interface ProductSchema {
  contract: string;
  prices: string;
  profitRate: number;
  stopLossPoint: string;
  stopProfitPoint: string;
  pointStep: string;
  calculatePoint: number;
  contractVolume: number;
}

export const readProducts = withoutCryptos(requestResult<ProductSchema[]>('/api/product/new/option/list'), 'contract')

export const create = withAccountUpdate(requestByAccountType<FastOrderSchema>('fastCreate'))
