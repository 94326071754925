/**
* @author zjc[beica1@outook.com]
* @date 2021/8/18 17:34
* @description
*   TheStopLines.vue of WeTrade
*/
<template>
  <div class="line profit" :style="{top: `${profitPosition}px`}">
    <span class="label">
      <t path="quickplay_11">Take profit</t>@<span class="c-success">{{profitPrice}}</span>
    </span>
  </div>
  <div class="line" :style="{top: `${lossPosition}px`}">
    <span class="f-sm label">
      <t path="quickplay_12">Stop loss</t>@<span class="c-danger">{{lossPrice}}</span>
    </span>
  </div>
</template>

<script lang="ts">
import { TradeDirection } from '@/types'
import { add } from 'essential/tools/math'
import { computed, defineComponent, shallowRef, watchEffect } from 'vue'

export default defineComponent({
  name: 'TheStopLines',
  props: {
    price: String,
    stops: {
      type: Object,
    },
    type: Number,
    fy: {
      type: Function,
      required: true,
    },
  },
  setup (props) {
    const profitPosition = shallowRef(0)
    const profitPrice = shallowRef(0)
    const lossPosition = shallowRef(0)
    const lossPrice = shallowRef(0)
    const stops = computed(() => props.stops)
    const factor = computed(() => props.type === TradeDirection.BUY ? 1 : -1)

    watchEffect(() => {
      if (!props.price) return
      if (!stops.value) return
      profitPrice.value = add(props.price, stops.value.profit * factor.value)
      lossPrice.value = add(props.price, -stops.value.loss * factor.value)
      profitPosition.value = props.fy(profitPrice.value)
      lossPosition.value = props.fy(lossPrice.value)
    })

    return {
      profitPosition,
      lossPosition,
      profitPrice,
      lossPrice,
    }
  },
})
</script>

<style scoped lang="scss">
.line {
  text-align: right;
  position: absolute;
  left: 0;
  border-bottom: 1px dashed var(--color-danger);
  right: 0;

  &.profit {
    border-color: var(--color-success);
  }
}

.label {
  position: absolute;
  right: 0;
  bottom: 0;
  color: var(--color-tip);
  font-size: 10px;
}
</style>
