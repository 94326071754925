<template>
  <div class="selector p-r">
    <Icon name="close" class="c-title close-icon" @click="$emit('close')" />
    <div class="selector-con">
      <Matrix
        #default="{ item, index, active }"
        :list="options" :select="value" column="3"
      >
        <div class="p-r option" :class="{ active }" @click="select(index)">
          <ChoiceButton :class="{active: value === item}">
            <p style="line-height: 24px;">
              <money :v="item.price" class="f-bold" />
            </p>
          </ChoiceButton>
        </div>
      </Matrix>
    </div>
  </div>
</template>

<script lang="ts">
import Icon from '@/components/Icon.vue'
import { computed, defineComponent, ref } from 'vue'
import Matrix from 'common/Matrix.vue'
import ChoiceButton from '@/components/ChoiceButton.vue'

export default defineComponent({
  name: 'TheSelectorDialog',
  components: { Icon, Matrix, ChoiceButton },
  emits: ['close', 'change'],
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: Number,
  },
  setup (props, ctx) {
    const current = ref(props.selected || 0)

    const value = computed(() => props.options[current.value])

    const select = (index: number) => {
      current.value = index
      ctx.emit('change', index)
      ctx.emit('close')
    }

    return {
      value,
      select,
    }
  },
})
</script>

<style>
  .selector-dialog {
    background: transparent;
  }
</style>

<style scoped lang="scss">

.selector {
  position: absolute;
  left: 16px;
  right: 16px;
  min-height: 100px;
  padding: 16px 16px 8px;
  background: var(--color-white);
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
  border-radius: 2px;
}

.close-icon {
  position: absolute;
  right: -8px;
  top: -8px;
  background: var(--color-grey);
  border-radius: 12px
}

</style>
